<template>
  <div :class="containerClasses">
    <div class="col-3 logo">
      <i
        v-if="!loading"
        :class="iconClasses"
      />
      <i
        v-else
        class="far fa-spinner fa-spin"
      />
    </div>
    <div class="col-9 title">
      {{ label }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OperatorSelectionBox',

  props: {
    label: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      required: true,
    },

    kind: {
      type: String,
      required: true,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props: { icon: string; kind: string}) {
    const iconClasses = `far ${props.icon}`;
    const containerClasses = {
      grid: true,
      'box-selection-display': true,
      'box-declencheur': props.kind === 'declencheur',
      'box-filtre': props.kind === 'filtre',
      'box-action': props.kind === 'action',
    };

    return {
      iconClasses,
      containerClasses,
    };
  },
});
</script>

<style lang="scss" scoped>
.box-selection-display {
  height: 50px;
  border-radius: 4px;
  cursor: pointer;
  width: 70%;
  margin: auto;
  padding: 0 10px;
  border-radius: 0.3em 0 0 0.3em;
  .logo {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    > i {
      font-size: 25px;
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 13px;
    padding: 0 10px;
    color: #495057;
    border-right: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    height: 100%;
    margin: 0;
    line-height: 1rem;
  }

  &.box-declencheur {
    border-color: #ffa31a;
    .logo {
      background-color: #ffa31a;
    }
  }
  &.box-filtre {
    border-color: #91c4f2;
    .logo {
      background-color: #91c4f2;
    }
  }
  &.box-action {
    border-color: #90d51c;
    .logo {
      background-color: #90d51c;
    }
  }
}
</style>
