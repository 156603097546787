
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OperatorSelectionBox',

  props: {
    label: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      required: true,
    },

    kind: {
      type: String,
      required: true,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props: { icon: string; kind: string}) {
    const iconClasses = `far ${props.icon}`;
    const containerClasses = {
      grid: true,
      'box-selection-display': true,
      'box-declencheur': props.kind === 'declencheur',
      'box-filtre': props.kind === 'filtre',
      'box-action': props.kind === 'action',
    };

    return {
      iconClasses,
      containerClasses,
    };
  },
});
