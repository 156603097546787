<template>
  <div
    :class="containerClasses"
    style="position: absolute;"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @click="(e) => onEditOperator(e)"
  >
    <slot name="prefix" />
    <div
      v-if="(hasPersistence || hasWarning) && !(disabled || grayscaled) && !visualisationMode"
      class="display-box-container-icon-outside"
    >
      <div v-if="hasWarning">
        <i
          v-tooltip="{ value: hasWarning, class: 'tooltip-icons-outside' }"
          class="fa fa-exclamation-triangle red icon-warning"
          data-html="true"
          data-toggle="tooltip"
          aria-hidden="true"
        />
      </div>

      <div v-if="hasPersistence">
        <i
          v-tooltip="{ value: getPersistenceTooltip(), class: 'tooltip-icons-outside' }"
          class="fab fa-product-hunt red icon-persistence"
          data-html="true"
          data-toggle="tooltip"
          aria-hidden="true"
        />
      </div>
    </div>
    <div
      v-if="analyticsMode.show && analyticsData !== null"
      class="flex justify-content-end flex-wrap display-box-container-icon-top"
    >
      <SpmOverlayPanel
        v-if="Object.prototype.hasOwnProperty.call(analyticsData, 'globalStatistics')"
        class-trigger="flex align-items-center justify-content-center text-xs border-round-sm dbcit-item"
        class-header="flex flex-wrap font-bold p-2"
        class-content="flex flex-column p-2 pt-3 pb-3"
        min-panel-width="320px"
        max-panel-width="420px"
      >
        <template #trigger>
          <span
            class="flex align-items-center justify-content-center"
          ><i class="fa-regular fa-chart-pie" /></span>
          <span class="ml-1">
            {{ t('campaigns.common.operatorAnalytics.globalStatistics.label') }}
          </span>
        </template>
        <template #header>
          <i :class="`${t('campaigns.common.operatorAnalytics.globalStatistics.icon')} mr-2`" />
          {{ t('campaigns.common.operatorAnalytics.globalStatistics.header') }}
        </template>
        <span
          v-for="(value, key) in analyticsData.globalStatistics"
          :key="key"
          class="flex justify-content-between text-sm mb-3"
        >
          <span class="mr-6">
            <i :class="`${t('campaigns.common.operatorAnalytics.globalStatistics.items.icons.'+key)} fa-fw mr-1`" />
            {{ t('campaigns.common.operatorAnalytics.globalStatistics.items.labels.'+key) }}
          </span>
          <div>
            <span
              class="text-sm font-semibold"
              :class="{ 'mr-1': Object.prototype.hasOwnProperty.call(value, 'percent') }"
            >{{ value.nb }}</span>
            <span
              v-if="Object.prototype.hasOwnProperty.call(value, 'percent')"
              class="text-xs font-normal"
            >({{ value.percent }}%)</span>
          </div>
        </span>
      </SpmOverlayPanel>

      <SpmOverlayPanel
        v-if="Object.prototype.hasOwnProperty.call(analyticsData, 'sendStatistics')"
        class-trigger="flex align-items-center justify-content-center text-xs border-round-sm ml-2 dbcit-item"
        class-header="flex flex-wrap font-bold p-2"
        class-content="flex flex-column p-2 pt-3 pb-3"
        min-panel-width="220px"
        max-panel-width="420px"
      >
        <template #trigger>
          <span
            class="flex align-items-center justify-content-center"
          ><i :class="t('campaigns.common.operatorAnalytics.sendStatistics.icon')" /></span>
          <span class="ml-1">
            {{ t('campaigns.common.operatorAnalytics.sendStatistics.label') }}
          </span>
        </template>
        <template #header>
          <i class="fa-regular fa-paper-plane-top mr-2" />
          {{ t('campaigns.common.operatorAnalytics.sendStatistics.header') }}
        </template>
        <span
          v-for="(value, key) in analyticsData.sendStatistics"
          :key="key"
          class="flex justify-content-between text-sm mb-2"
        >
          <span class="mr-6">
            <i :class="`${t('campaigns.common.operatorAnalytics.sendStatistics.items.icons.'+key)} fa-fw mr-1`" />
            {{ t('campaigns.common.operatorAnalytics.sendStatistics.items.labels.'+key) }}
          </span>
          <div>
            <span
              class="text-sm font-semibold"
              :class="{ 'mr-1': Object.prototype.hasOwnProperty.call(value, 'percent') }"
            >{{ formatNumbersInString(value.nb.toString()) }}</span>
            <span
              v-if="Object.prototype.hasOwnProperty.call(value, 'percent')"
              class="text-xs font-normal"
            >({{ value.percent }}%)</span>
          </div>
        </span>
      </SpmOverlayPanel>
    </div>
    <div class="display-box-container-icon-box ">
      <i
        v-if="!selectLoading"
        size="xs"
        :class="iconClasses"
      />
      <i
        v-else
        size="xs"
        class="far fa-spinner fa-spin"
      />
    </div>
    <div class="display-box-container-label-box">
      <span>{{ label }}</span>
    </div>
    <div
      v-if="!(disabled || grayscaled) && !visualisationMode"
      class="display-box-container-icon-actions"
    >
      <SpmOverlayPanel
        class-trigger="flex align-items-center justify-content-center icon-actions-operator"
        class-content="flex align-items-center justify-content-center spm-overlay-panel-content"
        :close-panel="closePanelActions"
        min-panel-width="150px"
        @onclose-panel="closePanelActions = false"
      >
        <template #trigger>
          <i class="far fa-ellipsis-v" />
        </template>
        <SpmPanelMenu
          :items="itemsActionOnOperator"
        />
      </SpmOverlayPanel>
    </div>
    <div
      v-if="!selectable && !visualisationMode"
      class="input-buttons-container"
    >
      <div
        v-if="!rootOfTree"
        :class="{ 'connectorCanBeMerged': input.connectorCanBeMerged,
                  'connectorSelectedForMerge': input.connectorSelectedForMerge }"
        @click="(e) => onMasterConnectorBtnClick(e, 'input', input)"
      >
        <i
          v-if="input.visible && !(disabled || grayscaled)"
          class="fa fa-plus"
          @click="(e) => onInputBtnClick(e)"
        />
      </div>
    </div>
    <div
      v-if="exchanges > 0 && !(disabled || grayscaled) && !visualisationMode"
      class="edit-operator-zone exchange-buttons-container"
    >
      <div
        v-for="(i) in exchanges"
        :key="i"
        :style="getPosExchange(i)"
      >
        <i
          class="fas fa-exchange fa-xs"
          @click="(e) => onExchangeBtnClick(e, i)"
        />
      </div>
    </div>
    <div
      v-if="outputs.length > 0 && !selectable && !visualisationMode"
      class="edit-operator-zone output-buttons-container"
    >
      <div
        v-for="(i) in outputs"
        :key="i.index"
        :class="{ 'connectorToMerge': i.connectorToMerge,
                  'connectorCanBeMerged': i.connectorCanBeMerged,
                  'connectorSelectedForMerge': i.connectorSelectedForMerge }"
        :style="getPosOutput(null, i.index)"
        @click.stop="(e) => i.visible && !(disabled || grayscaled) ? onOutputBtnClick(e, i.index) : onMasterConnectorBtnClick(e, 'output', i)"
      >
        <i
          v-if="i.visible && !(disabled || grayscaled)"
          class="fa fa-plus"
          @click.stop="(e) => onOutputBtnClick(e, i.index)"
        />
        <i
          v-else-if="kind === 'filtre'"
          :class="{ 'output-yes': i.index === 1, 'output-no': i.index === 2 }"
        >
          {{ i.index === 1 ? t('automatedScenarios.filters.field_outputs.output_yes') : t('automatedScenarios.filters.field_outputs.output_no') }}
        </i>
        <i
          v-else-if="!(disabled || grayscaled)"
          class="fa fa-circle"
          style="font-size: 6px !important;"
        />
      </div>
    </div>
    <div
      v-if="targetBulkCalculations !== null"
      class="flex display-target-bulk"
    >
      <div
        v-if="targetBulkCalculations === 'loading'"
        class="flex p-1"
      >
        <span
          class="flex align-items-center justify-content-center"
        ><i class="fa-thin fa-spinner fa-spin" /></span>
        <span class="text-xs ml-1">
          {{ t('loading') }}
        </span>
      </div>
      <div
        v-else
        class="flex p-1"
      >
        <span
          class="flex align-items-center justify-content-center"
        ><i class="fa-regular fa-down" /></span>
        <span class="ml-1">
          {{ targetBulkCalculations }} <small>contact{{ targetBulkCalculations > 1 ? 's' : '' }}</small>
        </span>
      </div>
    </div>
    <slot name="suffix" />
  </div>

  <div
    v-if="boxDialog !== null && boxDialog.type !== null && !visualisationMode"
  >
    <div
      v-if="boxDialog.type === BoxDialogType.ACTIONS_ADD_OPERATOR"
      class="boxDialogContainerDown"
      :style="getLeftPosBoxDialog()"
    >
      <Button
        :label="t('automatedScenarios.box_dialog.add_operator_actions.add')"
        class="p-button p-button-secondary"
        @click="(e) => onActionAddOperatorBtnClick(e, ActionsDialogAddOperatorType.ADD_OPERATOR)"
      />
      <Button
        :label="t('automatedScenarios.box_dialog.add_operator_actions.merge')"
        class="p-button p-button-secondary"
        @click="(e) => onActionAddOperatorBtnClick(e, ActionsDialogAddOperatorType.BRANCH_MERGE)"
      />
      <Button
        :label="t('automatedScenarios.box_dialog.add_operator_actions.closure')"
        class="p-button p-button-secondary"
        @click="(e) => onActionAddOperatorBtnClick(e, ActionsDialogAddOperatorType.BRANCH_CLOSURE)"
      />
    </div>
    <div
      v-else-if="boxDialog.type === BoxDialogType.ACTIONS_BRANCH_MERGE"
      class="boxDialogContainerDown"
      :style="getLeftPosBoxDialog()"
    >
      <small>{{ t('automatedScenarios.box_dialog.branch_merge.information') }}</small>
      <div
        v-if="boxDialog.error"
        class="errors"
      >
        {{ t('automatedScenarios.box_dialog.branch_merge.error') }}
      </div>
      <Button
        :label="t('automatedScenarios.box_dialog.branch_merge.validate')"
        class="p-button p-button-primary"
        @click="(e) => onActionBranchMergeBtnClick(e, ActionsDialogBranchMergeType.VALIDATE)"
      />
      <Button
        :label="t('automatedScenarios.box_dialog.branch_merge.cancel')"
        class="p-button p-button-danger"
        @click="(e) => onActionBranchMergeBtnClick(e, ActionsDialogBranchMergeType.CANCEL)"
      />
    </div>
    <div
      v-else-if="boxDialog.type === BoxDialogType.ACTIONS_BRANCH_CLOSURE"
      class="boxDialogContainerDown"
      :style="getLeftPosBoxDialog()"
    >
      <small>{{ t('automatedScenarios.box_dialog.branch_closure.information') }}</small>
      <div
        v-if="boxDialog.error"
        class="errors"
      >
        {{ t('automatedScenarios.box_dialog.branch_merge.error') }}
      </div>
      <Button
        :label="t('automatedScenarios.box_dialog.branch_closure.validate')"
        class="p-button p-button-primary"
        @click="(e) => onActionBranchClosureBtnClick(e, ActionsDialogBranchClosureType.VALIDATE)"
      />
      <Button
        :label="t('automatedScenarios.box_dialog.branch_closure.cancel')"
        class="p-button p-button-danger"
        @click="(e) => onActionBranchClosureBtnClick(e, ActionsDialogBranchClosureType.CANCEL)"
      />
    </div>
    <div
      v-else-if="boxDialog.type === BoxDialogType.CONFIRMATION_BRANCH_CLOSURE"
      class="boxDialogContainerDown"
      :style="getLeftPosBoxDialog()"
    >
      <Button
        :label="t('automatedScenarios.box_dialog.branch_closure.cancel')"
        class="p-button p-button-danger"
        @click="(e) => onActionBranchClosureBtnClick(e, ActionsDialogBranchClosureType.CANCEL)"
      />
    </div>
    <div
      v-else-if="boxDialog.type === BoxDialogType.ACTIONS_DELETE_OPERATOR"
      class="boxDialogContainerRight"
    >
      <small>{{ t('automatedScenarios.box_dialog.delete_operator.information') }}</small>
      <Button
        :label="t('automatedScenarios.box_dialog.delete_operator.validate')"
        class="p-button p-button-primary"
        @click="(e) => onActionRemoveOperatorBtnClick(e, ActionsDialogRemoveOperator.VALIDATE)"
      />
      <Button
        :label="t('automatedScenarios.box_dialog.delete_operator.cancel')"
        class="p-button p-button-danger"
        @click="(e) => onActionRemoveOperatorBtnClick(e, ActionsDialogRemoveOperator.CANCEL)"
      />
    </div>
    <div
      v-else-if="boxDialog.type === BoxDialogType.INFO_RIGHT && boxDialog.message"
      class="boxDialogContainerRight"
    >
      <small v-html="t(boxDialog.message)" />
      <Button
        :label="t('automatedScenarios.box_dialog.delete_operator.understood')"
        class="p-button p-button-primary"
        @click="(e) => onActionRemoveOperatorBtnClick(e, ActionsDialogRemoveOperator.CANCEL)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';
import {
  computed, defineComponent, onBeforeUnmount, onMounted, PropType, Ref, ref, SetupContext,
} from 'vue';
import Tooltip from 'primevue/tooltip';
import { asInt } from '@/helpers';
import {
  ActionsDialogAddOperatorType,
  ActionsDialogBranchClosureType,
  ActionsDialogBranchMergeType,
  ActionsDialogRemoveOperator,
  AutomatedScenarioAnalytics,
  AutomatedScenarioState as state,
  BoxDialogType,
  compatibilityOperatorsCheck,
  isRootOfTree,
  OperatorInput,
  OperatorOutput,
  setAnalyticsDataToOperator,
  getFilterSegmentById, checkIfFilterLostLinkedBox,
} from '@/composables/AutomatedScenarios';
import {
  checkIfSendingChannelBoxLostConfig,
  sendingChannelBoxes,
} from '@/composables/automated-scenarios/AutomatedScenariosSendingChannel';
import { PrimvueMenuModel, TypeCampaignEnum } from '@/types';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import { getComponentMetadataById } from '@/components/automated-scenarios/metadata';
import { GlobalBulkSettingsData } from '@/types/automated-scenarios';
import { formatNumbersInString } from '@/helpers/Number';
import { TemplateTypeEnum } from '@/composables/shop/Templates';

export default defineComponent({
  name: 'OperatorDisplayBox',

  directives: {
    tooltip: Tooltip,
  },

  components: {
    Button,
    SpmOverlayPanel,
    SpmPanelMenu,
  },

  props: {
    operatorId: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      required: true,
    },

    hasConfiguration: {
      type: Boolean,
      required: true,
    },

    kind: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    grayscaled: {
      type: Boolean,
      required: false,
      default: false,
    },

    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },

    input: {
      type: Object as PropType<OperatorInput>,
      required: false,
      default: () => Object,
    },

    outputs: {
      type: Array as PropType<OperatorOutput[]>,
      required: false,
      default: () => [],
    },

    analyticsMode: {
      type: Object as PropType<AutomatedScenarioAnalytics>,
      required: true,
    },

    visualisationMode: {
      type: Boolean,
      required: false,
      default: false,
    },

    selectLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    id: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: [
    'merge-connector',
    'input-clicked',
    'output-clicked',
    'exchange-clicked',
    'action-add-operator-clicked',
    'action-branch-merge-clicked',
    'action-branch-closure-clicked',
    'action-remove-operator-clicked',
    'edit-operator',
    'replace-operator',
    'highlighted',
    'unhighlighted',
    'send-test-message',
  ],

  setup(props: { operatorId: string; icon: string; hasConfiguration: boolean; kind: string; disabled: boolean;
    grayscaled: boolean; selectable: boolean; input: OperatorInput; outputs: OperatorOutput[];
    analyticsMode: AutomatedScenarioAnalytics; visualisationMode: boolean; id: string;}, context: SetupContext) {
    const { t, te } = useI18n();
    const targetBulkCalculations: any = ref(null);
    const datas = computed(() => state.scenario.getOperatorData<any>(props.operatorId));

    const hasPersistence = computed(() => Object.keys(datas.value.persistanceList ?? {}).length > 0);
    const iconClasses = `far ${props.icon}`;
    const containerClasses = computed(() => ({
      'display-box-container': true,
      'display-analytics-mode': props.analyticsMode.show,
      'display-box-declencheur': props.kind === 'declencheur',
      'display-box-filtre': props.kind === 'filtre',
      'display-box-action': props.kind === 'action',
      'display-box-disabled': props.disabled,
      'display-box-grayscaled': props.grayscaled,
      'display-box-selectable': props.selectable,
    }));
    const closePanelActions = ref(false);

    const analyticsData = computed(() => {
      const data = state.operators.filter((o) => o.operatorId === props.operatorId)[0];
      return data.analyticsData;
    });

    const exchanges = computed(() => props.outputs?.length - 1);
    const sendingChannelBoxLostConfig = ref(false);
    const isOperatorValid = ref(true);
    const hasNoContacts = ref(false);
    const queuedPendingImportLists = ref(0);

    const hasWarning = computed(() => {
      const warningMessage = [];
      const incompatibleWithParents = !compatibilityOperatorsCheck(props.operatorId);

      if (incompatibleWithParents) {
        warningMessage.push(`<li>- ${t('automatedScenarios.tooltips.incompatibilityOperator')}</li>`);
      }

      if (!isOperatorValid.value) {
        warningMessage.push(`<li>- ${t('automatedScenarios.tooltips.operatorIncompleteConfiguration')}</li>`);
      }

      if (sendingChannelBoxLostConfig.value) {
        warningMessage.push(`<li>- ${t('automatedScenarios.tooltips.operatorLostVoucherConfiguration')}`);
      }

      if (hasNoContacts.value) {
        warningMessage.push(`<li>- ${t('automatedScenarios.tooltips.hasNoContacts')}</li>`);
      }

      if (queuedPendingImportLists.value) {
        const mainMessageKey = 'automatedScenarios.tooltips.queuedPendingListsImport.main';
        const importsMessageKey = `automatedScenarios.tooltips.queuedPendingListsImport.import${queuedPendingImportLists.value > 1 ? 's' : ''}`;
        warningMessage.push(`<li>- ${t(mainMessageKey, [queuedPendingImportLists.value, t(importsMessageKey)])}</li>`);
      }

      return warningMessage.length ? `<ul>${warningMessage.join('')}</ul>` : false;
    });

    const rootOfTree = computed(() => isRootOfTree(props.operatorId));

    const boxHover = ref(false);

    const boxDialog = computed(() => {
      if (state.selectedOperator.operatorId === props.operatorId
        && state.selectedOperator.dialog !== null) {
        return state.selectedOperator.dialog;
      }
      return null;
    });

    const onMasterConnectorBtnClick = (e: PointerEvent, type: string, connector: OperatorInput|OperatorOutput) => {
      e.preventDefault();
      if (connector.connectorCanBeMerged || connector.connectorSelectedForMerge) {
        context.emit('merge-connector', e, type, connector);
      }
    };

    const onActionAddOperatorBtnClick = (e: PointerEvent, type: ActionsDialogAddOperatorType) => {
      e.preventDefault();
      context.emit('action-add-operator-clicked', e, type);
    };

    const onActionBranchMergeBtnClick = (e: PointerEvent, type: ActionsDialogBranchMergeType) => {
      e.preventDefault();
      context.emit('action-branch-merge-clicked', e, type);
    };

    const onActionBranchClosureBtnClick = (e: PointerEvent, type: ActionsDialogBranchClosureType) => {
      e.preventDefault();
      context.emit('action-branch-closure-clicked', e, type);
    };

    const onActionRemoveOperatorBtnClick = (e: PointerEvent|null, type: ActionsDialogRemoveOperator) => {
      if (e !== null) {
        e.preventDefault();
      }
      context.emit('action-remove-operator-clicked', e, type);
    };

    const onInputBtnClick = (e: PointerEvent) => {
      e.preventDefault();
      context.emit('input-clicked', e);
    };

    const onOutputBtnClick = (e: PointerEvent, i: string | number) => {
      e.preventDefault();
      context.emit('output-clicked', e, asInt(i));
    };

    const onExchangeBtnClick = (e: PointerEvent, i: string | number) => {
      e.preventDefault();
      context.emit('exchange-clicked', e, asInt(i));
    };

    const onEditOperator = (e: any) => {
      const target = e.originalTarget ?? e.target;

      if (target && target.className.includes('edit-operator-zone')
        && !(props.disabled || props.grayscaled)) {
        e.preventDefault();
        context.emit('edit-operator');
      }
    };

    const onMouseEnter = () => {
      boxHover.value = true;
      context.emit('highlighted');
    };

    const onMouseLeave = () => {
      boxHover.value = false;
      context.emit('unhighlighted');
    };

    const getPosOutput = (operatorId: string|null, output: number): string => {
      let idOperator = props.operatorId;
      if (operatorId !== null) {
        idOperator = operatorId;
      }

      const operatorOutputs = Array.from(document.querySelectorAll(`#flowchart_operator_${idOperator}
            .flowchart-operator-outputs .flowchart-operator-connector-set`)) as HTMLElement[];

      if (typeof (operatorOutputs[output - 1]) !== 'undefined') {
        const outputConnectorElementStyle = window.getComputedStyle(operatorOutputs[output - 1], null);
        const flowchartOutputLeft = outputConnectorElementStyle.left.match(/\d+/g);
        const flowchartOutputLeftInt = flowchartOutputLeft ? parseInt(flowchartOutputLeft[0], 10) - 1 : 0;
        return `left: ${flowchartOutputLeftInt}px;`;
      }

      return 'left: 0px;';
    };

    const getLeftPosBoxDialog = (): string => {
      if (state.selectedOperator.operatorId && state.selectedOperator.outputSelected) {
        return getPosOutput(state.selectedOperator.operatorId, parseInt(state.selectedOperator.outputSelected, 10));
      }
      return 'left: 24px;';
    };

    const getPosExchange = (index: number) => {
      const operatorOutputs = Array.from(document.querySelectorAll(`#flowchart_operator_${props.operatorId}
            .flowchart-operator-outputs .flowchart-operator-connector-set`)) as HTMLElement[];

      const lengthBox = operatorOutputs.length > 2 ? 225 : 50;
      const space = lengthBox / (operatorOutputs.length - 1);
      if (typeof operatorOutputs[index - 1] !== 'undefined') {
        const outputConnectorElementStyle = window.getComputedStyle(operatorOutputs[index - 1], null);
        const flowchartOutputLeft = outputConnectorElementStyle.left;

        const left = parseFloat(flowchartOutputLeft) + space / 2;
        return `left: ${left}px;`;
      }
      return '';
    };

    const getPersistenceTooltip = () => `${t('automatedScenarios.tooltips.persistence.title')}
    <ul>${Object.entries(datas.value.persistanceList).map((current: any) => {
    const subTypes = current[1].map((entry: string) => (te(`automatedScenarios.tooltips.persistence.subType.${entry}`)
      ? `<li>* ${t(`automatedScenarios.tooltips.persistence.subType.${entry}`)}</li>`
      : ''))
      .filter((entry: string) => entry !== '');
    return `<li>- ${t(`automatedScenarios.tooltips.persistence.mainType.${current[0]}`)} <ul class="sub-type">${subTypes.join('')}</ul></li>`;
  }).join('')}</ul>`;

    const itemsActionOnOperator: Ref<PrimvueMenuModel[]> = ref([
      {
        label: t('campaigns.common.operatorActions.edit'),
        icon: 'fa-regular fa-pen-to-square',
        command: () => {
          context.emit('edit-operator');
          closePanelActions.value = true;
        },
      },
      {
        label: t('campaigns.common.operatorActions.replace'),
        icon: 'fa-regular fa-rotate',
        command: () => {
          context.emit('replace-operator');
          closePanelActions.value = true;
        },
      },
      {
        label: t('campaigns.common.operatorActions.sendTestMessage'),
        icon: 'far fa-fw fa-paper-plane',
        command: () => {
          const templateType = props.id === 'boxsendemail' ? TemplateTypeEnum.EMAIL : TemplateTypeEnum.SMS;
          context.emit('send-test-message', props.operatorId, templateType);
          closePanelActions.value = true;
        },
        hide: !['boxsendemail', 'boxsendsms'].includes(props.id),
      },
      {
        label: t('campaigns.common.operatorActions.remove'),
        icon: 'fa-regular fa-trash',
        command: () => {
          onActionRemoveOperatorBtnClick(null, ActionsDialogRemoveOperator.OPEN_DIALOG);
          closePanelActions.value = true;
        },
      },
    ]);

    const validateOperator = async () => {
      if (state.scenario.type === TypeCampaignEnum.BULK && !props.visualisationMode) {
        const data = state.scenario.getOperatorData<any>(props.operatorId);
        if (data) {
          const metadata = getComponentMetadataById(data.id);
          if (metadata && metadata.Validate) {
            const validation = await metadata.Validate(data);
            if (!validation.success) {
              isOperatorValid.value = false;
            } else {
              const {
                exclusion,
                inclusion,
                categories,
                products,
                manufacturers,
                urls,
              } = data;

              const checkFilterValidation = async (filterData: any, filterSegmentId: string) => {
                const filterSegment = await getFilterSegmentById(filterSegmentId);

                if (filterSegment && filterSegment.validate && filterData) {
                  const fields = filterSegment.fields.map((field) => field.id);
                  const dataToValidate: Record<string, any> = {};

                  // eslint-disable-next-line no-restricted-syntax
                  for (const field of fields) {
                    const fieldData = filterData[field];
                    dataToValidate[field] = Array.isArray(fieldData) && fieldData.length ? fieldData[0] : fieldData;
                  }

                  const filterSegmentValidation = await filterSegment.validate(dataToValidate);

                  return filterSegmentValidation.success;
                }

                return true;
              };

              const checkFilterOptions = async (filter: Record<string, any>) => {
                const keys = Object.keys(filter);
                if (keys.length) {
                  // eslint-disable-next-line no-restricted-syntax
                  for (const key of keys) {
                    const filterData = filter[key];

                    // eslint-disable-next-line no-await-in-loop
                    const filterValidation = await checkFilterValidation(filterData, key);

                    if (!filterValidation) {
                      return false;
                    }
                  }
                }
                return true;
              };

              if (isOperatorValid.value && exclusion) {
                isOperatorValid.value = await checkFilterOptions(exclusion);
              }
              if (isOperatorValid.value && inclusion) {
                isOperatorValid.value = await checkFilterOptions(inclusion);
              }
              if (isOperatorValid.value && categories && typeof categories === 'object' && !Array.isArray(categories)) {
                isOperatorValid.value = await checkFilterValidation(categories, 'categories');
              }

              if (isOperatorValid.value && products && typeof products === 'object' && !Array.isArray(products)) {
                isOperatorValid.value = await checkFilterValidation(products, 'products');
              }
              if (isOperatorValid.value && manufacturers && typeof manufacturers === 'object' && !Array.isArray(manufacturers)) {
                isOperatorValid.value = await checkFilterValidation(manufacturers, 'manufacturers');
              }
              if (isOperatorValid.value && urls && typeof urls === 'object' && !Array.isArray(urls)) {
                isOperatorValid.value = await checkFilterValidation(urls, 'urls');
              }
            }
          }
        }
      }
    };

    const intervalCalculationId = ref();
    const intervalIsActive = ref(true); 

    const checkImportState = async () => {
      if (state.scenario.type === TypeCampaignEnum.BULK && !props.visualisationMode) {
        const metadata = getComponentMetadataById(datas.value.id ?? '');
        if (metadata && metadata.CheckImportState) {
          queuedPendingImportLists.value = await metadata.CheckImportState(state.scenario.data, datas.value);
        }
      }
    };

    const calculateTargetBulk = async () => {
      if (state.scenario.type === TypeCampaignEnum.BULK && !props.visualisationMode) {
        const metadata = getComponentMetadataById(datas.value.id ?? '');
        if (metadata && metadata.TargetBulkCalcul) {
          targetBulkCalculations.value = 'loading';
          await checkImportState();
          targetBulkCalculations.value = await metadata.TargetBulkCalcul(
            state.scenario.data,
            datas.value,
            (state.scenario.settingsData as GlobalBulkSettingsData).commercial_campaign ?? 1,
          );
          if (targetBulkCalculations.value === 0) {
            hasNoContacts.value = true;
          } else if (targetBulkCalculations.value > 0) {
            hasNoContacts.value = false;
          }
        }
        if (intervalIsActive.value) {
          intervalCalculationId.value = setTimeout(async () => {
            await calculateTargetBulk();
          }, 5000);
        }
      }
    };

    onMounted(async () => {
      sendingChannelBoxLostConfig.value = sendingChannelBoxes.includes(datas.value.id)
        ? await checkIfSendingChannelBoxLostConfig(props.operatorId) : false;

      // Si les données analytics de cet operator sont null et que le mode analytics est activé, on les récupère
      if (analyticsData.value === null && props.analyticsMode.show) {
        await setAnalyticsDataToOperator(props.operatorId);
      }

      await checkIfFilterLostLinkedBox(datas.value.id, props.operatorId);

      await calculateTargetBulk();

      // Real time operator validation
      await validateOperator();
    });

    onBeforeUnmount(() => {
      intervalIsActive.value = false;
      if (intervalCalculationId.value) {
        clearTimeout(intervalCalculationId.value);
      }
    });

    return {
      t,
      analyticsData,
      onMasterConnectorBtnClick,
      iconClasses,
      containerClasses,
      rootOfTree,
      getPosOutput,
      getLeftPosBoxDialog,
      getPosExchange,
      onInputBtnClick,
      hasPersistence,
      exchanges,
      onOutputBtnClick,
      onExchangeBtnClick,
      onEditOperator,
      onMouseEnter,
      onMouseLeave,
      getPersistenceTooltip,
      hasWarning,
      boxDialog,
      onActionAddOperatorBtnClick,
      onActionBranchMergeBtnClick,
      onActionBranchClosureBtnClick,
      onActionRemoveOperatorBtnClick,
      ActionsDialogAddOperatorType,
      ActionsDialogBranchMergeType,
      ActionsDialogBranchClosureType,
      ActionsDialogRemoveOperator,
      BoxDialogType,
      itemsActionOnOperator,
      closePanelActions,
      targetBulkCalculations,
    };
  },

  methods: { formatNumbersInString },

});
</script>

<style lang="scss">
.boxDialogContainerDown {
  top: 70px; left: 24px;
  color: #333333;
  line-height: 1.4em;
  padding: 5px 10px;
  position: absolute;
  transform: translateX(-50%);
  width: 270px;
  background: #ffffff;
  border: solid 1px #dedede;
  border-radius: 5px;
  -moz-box-shadow: 5px 5px 8px #dedede;
  -webkit-box-shadow: 5px 5px 8px #dedede;
  box-shadow: 5px 5px 8px #dedede;
  z-index: 10000;

  &:after, &:before {
    left: 50%;
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 9px;
    margin-left: -8px;
  }

  &:before {
    border-color: rgba(222, 222, 222, 0);
    border-bottom-color: #dedede;
    border-width: 10px;
    margin-left: -9px;
  }

  .p-button {
    width: 100%;
    margin-top: 5px;
  }

  .errors {
    color: #D32F2F;
    font-size: 12px;
  }
}

.boxDialogContainerRight {
  top: 25px;
  left: 245px;
  line-height: 1.4em;
  z-index: 10000;
  padding: 5px 10px;
  position: absolute;
  transform: translateY(-50%);
  width: 285px;
  background: #ffffff;
  border: solid 1px #dedede;
  border-radius: 5px;
  -moz-box-shadow: 5px 5px 8px #dedede;
  -webkit-box-shadow: 5px 5px 8px #dedede;
  box-shadow: 5px 5px 8px #dedede;

  &:after, &:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #ffffff;
    border-width: 10px;
    margin-top: -10px;
  }

  &:before {
    border-color: rgba(222, 222, 222, 0);
    border-right-color: #dedede;
    border-width: 11px;
    margin-top: -11px;
  }

  .p-button {
    width: 100%;
    margin-top: 5px;
  }

  .errors {
    color: #D32F2F;
    font-size: 12px;
  }
}

.flowchart-operator {
  border: none !important;
}

.display-box-container {
  width: 230px;
  height: 50px;
  background-color: #fff;
  border-radius: 0.4em;
  display: flex;
  cursor: all-scroll;

  .display-analytics-data {
    width: 100%;
    .p-chip {
      opacity: 0.7;
      height: 1.5rem;
      color: white;
      width: 90%;

      .p-chip-text {
        font-size: 0.7rem;
      }
    }
  }

  .display-box-container-icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 50px;
    color: #fff;
    padding: 0 10px;
    opacity: 0.7;
    border-radius: 0.3em 0 0 0.3em;

  }

  .display-box-container-label-box {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-basis: 180px;
    font-size: 12px;
    padding: 0 10px;
    color: $tuna;
    border-right: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }

  &.display-box-declencheur {
    //border: solid 1px #ffa31a;
    .display-box-container-icon-box {
      background-color: #ffa31a;
    }

    .display-analytics-data {
      width: 100%;
      .p-chip {
        background-color: #ffa31a;
      }
    }
  }

  &.display-box-action {
    //border: solid 1px #90d51c;

    .display-box-container-icon-box {
      background-color: #90d51c;
    }

    .display-analytics-data {
      width: 100%;
      .p-chip {
        background-color: #90d51c;
      }
    }
  }

  &.display-box-filtre {
    //border: solid 1px #91c4f2;

    .display-box-container-icon-box {
      background-color: #91c4f2;
    }

    .display-analytics-data {
      width: 100%;
      .p-chip {
        background-color: #91c4f2;
      }
    }
  }

  &.display-box-grayscaled {
    border: solid 1px #E9E9E9;

    .display-box-container-icon-box {
      background-color: #E9E9E9;
    }
    .display-box-container-label-box {
      color: #979797;
    }
  }

  &.display-box-disabled {
    border: solid 1px #E9E9E9;

    .display-box-container-icon-box {
      background-color: #E9E9E9;
    }
    .display-box-container-label-box {
      color: #979797;
    }
  }

  &.display-box-selectable {
    box-shadow: 0 0 25px 5px $extra-light-grey;
  }
}

.display-box-container-icon-actions {
  position: absolute;
  z-index: 10;
  right: 0.4rem;
  top: 0.4rem;
  > .icon-actions-operator {
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    padding: 0.3rem;

    &:hover {
      background-color: #f2f2f2;
    }

    > i {
      color: #495057;
      vertical-align: middle;
      text-align: center;
    }
  }
}

.input-buttons-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;

  > div {
    position: absolute;
    top: -1px;
    left:24px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    background-color: white;
    width: 20px;
    height: 20px;
    border-radius: 25px;
    border: 1px solid #cecece;

    &.connectorCanBeMerged {
      background-color: #FFC56E !important;
      border: solid 1px #FFC56E !important;
    }

    &.connectorSelectedForMerge {
      background-color: #93c840 !important;
      border: solid 1px #93c840 !important;
    }

    > i {
      color: #93c840;
      font-size: 12px;
      position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.output-buttons-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;

  > div {
    position: absolute;
    cursor: pointer;
    top: 100%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 25px;
    border: 1px solid #cecece;

    &.connectorToMerge {
      background-color: #93c840 !important;
      border: solid 1px #93c840 !important;
    }

    &.connectorCanBeMerged {
      background-color: #FFC56E !important;
      border: solid 1px #FFC56E !important;
    }

    &.connectorSelectedForMerge {
      background-color: #93c840 !important;
      border: solid 1px #93c840 !important;
    }

    > i {
      position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #93c840;
      font-size: 12px;

      &.output-yes {
        font-weight: bold;
        font-style: normal;
        color: $brand-color-primary;
      }

      &.output-no {
        font-weight: bold;
        font-style: normal;
        color: #D8000C;
      }
    }
  }
}

.exchange-buttons-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;

  > div {
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    cursor: pointer;
    > i {
      color: #B1B1B1;
      &:hover {
        color: #333333;
      }
    }
  }
}

.display-box-container-icon-outside {
  position: absolute;
  height: 100%;
  left: -30px;

  > div {
    position: absolute;
    cursor: default;

    &:first-child {
      top: 5%;
    }

    &:last-child {
      top: 60%;
    }

    &:only-child {
      top: 30%;
    }

    > i.icon-warning {
      color: #FFD736;
    }
    > i.icon-persistence {
      color: red;
    }
  }
}

.display-box-container-icon-top {
  position: absolute;
  width: 100%;
  top: -1.5rem;

  .dbcit-item {
    background-color: #495057;
    color: #f6f6f7;
    padding: 0.2rem;
    cursor: pointer;

    &:hover {
      background-color: #343a40;
    }
  }
}

.display-target-bulk {
  position: absolute;
  width: 100%;
  top: 4.5rem;

  > div {
    background-color: white;
    border: 1px solid #dee2e6;
    border-radius: 3px;
  }
}

.tooltip-icons-outside .p-tooltip-text {
  min-width: 20rem;
}
.tooltip-icons-outside ul.sub-type {
  padding-left: 20px;
  list-style: none
}

.edit-operator-zone {
  cursor: pointer;
}
</style>
