<template>
  <div
    v-if="step === StepsOperatorSelector.SELECT"
    class="boxes-listing-container"
  >
    <div
      v-if="state.selectedOperator.possibleOutputs.declencheur.length > 0"
    >
      <h3>{{ t('Déclencheurs') }}</h3>
      <div
        class="grid"
      >
        <div
          v-for="(item, i) in state.selectedOperator.possibleOutputs.declencheur"
          :key="i"
          class="col-12"
        >
          <OperatorSelectionBox
            :kind="item.Meta.kind"
            :icon="item.Meta.icon"
            :label="t(item.Meta.label)"
            @click="select(item.Meta)"
          />
        </div>
      </div>
    </div>

    <div
      v-if="state.selectedOperator.possibleOutputs.action.length > 0"
    >
      <h3>{{ t('Actions') }}</h3>
      <div
        class="grid"
      >
        <div
          v-for="(item, i) in state.selectedOperator.possibleOutputs.action"
          :key="i"
          class="col-12"
        >
          <OperatorSelectionBox
            :kind="item.Meta.kind"
            :icon="item.Meta.icon"
            :label="t(item.Meta.label)"
            :loading="selectLoading[item.Meta.label]"
            @click="select(item.Meta)"
          />
        </div>
      </div>
    </div>

    <div
      v-if="state.selectedOperator.possibleOutputs.filtre.length > 0"
    >
      <h3>{{ t('Filtres') }}</h3>
      <div
        class="grid"
      >
        <div
          v-for="(item, i) in state.selectedOperator.possibleOutputs.filtre"
          :key="i"
          class="col-12"
        >
          <OperatorSelectionBox
            :kind="item.Meta.kind"
            :icon="item.Meta.icon"
            :label="t(item.Meta.label)"
            @click="select(item.Meta)"
          />
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="step === StepsOperatorSelector.CONFIGURE"
    class="configuration-wrapper"
    :class="selected.kind !== 'filtre' ? 'field-group-content' : ''"
  >
    <div
      class="configuration-wrapper-content"
    >
      <component
        :is="selected.component"
        :selected-component="selected.component"
        v-model="data"
        v-model:action="action"
        v-model:form-validation="formValidation"
        :id-shop="idShop"
      />
    </div>

    <div
      v-if="!configuringSegment"
      class="configuration-action-container flex p-2"
    >
      <span class="p-buttonset">
        <SpmButton
          :label="t('cancel')"
          icon="fa-regular fa-xmark"
          class-style="p-button p-button-secondary"
          @click="cancel"
        />
        <SpmButton
          :label="t('automatedScenarios.addBox')"
          icon="fa-regular fa-plus"
          class-style="p-button p-button-primary"
          @click="add"
        />
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeMount,
  ref,
  SetupContext,
  toRefs,
  watch,
  Ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import OperatorSelectionBox from '@/components/automated-scenarios/OperatorSelectionBox.vue';
import OperatorDisplayBox from '@/components/automated-scenarios/OperatorDisplayBox.vue';
import Tooltip from 'primevue/tooltip';
import {
  addOperator,
  AutomatedScenarioState as state,
  backOperatorSelect,
  getDisplayUrl,
  OperatorMeta,
  resetDefaultDisplayUrl,
  setCurrentConfigurationForm,
  setDefaultDisplayUrl,
  setPersistence,
  StepsOperatorSelector,
  updateOperatorSelectorStep,
  refreshFlowchart,
} from '@/composables/AutomatedScenarios';
import ConfigComponents from '@/components/automated-scenarios/forms';
import Metadata, { getComponentMetadata } from '@/components/automated-scenarios/metadata';
import { UserState } from '@/composables/User';
import { showToastError } from '@/helpers';
import { TypeCampaignEnum } from '@/types';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { checkIfServiceNeedToBeVerified, getNotConfiguredServices } from '@/composables/shop/MyShopParameters';
import { getServicesToVerify } from '@/configs/services-parameters';
import { useStore } from '@/store';

export default defineComponent({
  name: 'OperatorSelector',

  components: {
    SpmButton,
    OperatorSelectionBox,
    ...ConfigComponents,
    Button,
    OperatorDisplayBox,
    Accordion,
    AccordionTab,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    signalSave: {
      type: Boolean,
      required: false,
      default: () => false,
    },

    actionAfterSave: {
      type: Object as any,
      required: false,
      default: () => ({}),
    },
  },

  emits: ['save', 'edit', 'reset-action-after-save'],

  setup(props, context: SetupContext) {
    const { t } = useI18n();
    const store = useStore();
    const { signalSave } = toRefs(props);
    const { actionAfterSave } = toRefs(props);
    const formValidation = ref<any>({});

    const data = ref<any>({});
    const selected = ref<OperatorMeta | null>(null);
    const step = computed(() => state.leftToolbar.operatorSelectorStep);
    const configuringSegment = computed(() => state.configuringSegment);

    const action = ref('none');
    const idShop = UserState.activeShop?.id ?? 0;

    const selectLoading: Ref<Record<string, boolean>> = ref({});

    const add = async () => {
      const component = selected.value?.component;
      if (!component) {
        return;
      }

      const meta = getComponentMetadata(component);
      if (!meta) {
        return;
      }

      if (meta.Validate) {
        try {
          const validation = await meta.Validate(data.value);
          if (!validation.success) {
            formValidation.value = validation.validate;
            await showToastError(t('errorMessages.FORM_ERROR'));
            return;
          }
        } catch (error) {
          console.error(error);
          return;
        }
      }

      if (meta.BeforeInsertData) {
        data.value = meta.BeforeInsertData(data.value);
      }

      addOperator(component, data.value);
      setPersistence(state.selectedOperator.operatorId);
      refreshFlowchart();
      if (meta.Callback) {
        meta.Callback(data.value);
      }

      if (actionAfterSave.value && actionAfterSave.value.action) {
        actionAfterSave.value.action();
        context.emit('reset-action-after-save');
      }
    };

    const findNearestParent = (givenOperatorId: any) => {
      // Create a mapping of child operators to their parent operators
      const operatorParentMap: Record<any, any> = {};

      const linkObject = JSON.parse(JSON.stringify(state.scenario.data.links));

      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const linkKey in linkObject) {
        const link = linkObject[linkKey];
        const childOperatorId = link.toOperator;
        const parentOperatorId = link.fromOperator;

        if (!operatorParentMap[childOperatorId]) {
          operatorParentMap[childOperatorId] = [];
        }

        operatorParentMap[childOperatorId].push(parentOperatorId);
      }

      // Find the nearest parent operator with "id" equal to "boxvisitpage" using DFS
      function findNearestParentWithId(operatorId: any, nearestParentId: any): any {
        const parentOperators = operatorParentMap[operatorId] || [];

        // eslint-disable-next-line no-restricted-syntax
        for (const parentOperatorId of parentOperators) {
          const parentOperator = state.operators.find((operator) => operator.operatorId === parentOperatorId);

          if (parentOperator && parentOperator.id === 'boxvisitpage') {
            return parentOperatorId;
          }

          // Update the nearestParent if the current parent is closer to the desired "id"
          if (!nearestParentId || parentOperators.indexOf(nearestParentId) > parentOperators.indexOf(parentOperatorId)) {
            // eslint-disable-next-line no-param-reassign
            nearestParentId = parentOperatorId;
          }

          // Recursively check parent's parents until a match is found
          const nearestParentObject = findNearestParentWithId(parentOperatorId, nearestParentId);

          if (nearestParentObject) {
            return nearestParentObject;
          }
        }

        return nearestParentId;
      }

      return findNearestParentWithId(givenOperatorId, null);
    };

    const select = async (item: OperatorMeta, dataValue: any = null) => {
      const meta = getComponentMetadata(item.component);
      if (!meta) {
        return;
      }

      selected.value = item;
      if (dataValue !== null) {
        data.value = dataValue;
      } else {
        data.value = meta.Create();
      }

      selectLoading.value[item.label] = true;

      if (meta.Meta.hasConfiguration) {
        const serviceParameterSuccessAction = async () => {
          await nextTick();
          let defaultDisplayUrl = '';
          if (state.selectedOperator.currentConfigurationForm.id === 'boxdisplayfreepopup' || state.selectedOperator.currentConfigurationForm.id === 'boxdisplayfreemodel') {
            const parentOperatorId = state.selectedOperator.meta.id === 'boxvisitpage' ? state.selectedOperator.operatorId : findNearestParent(state.selectedOperator.operatorId);
            if (parentOperatorId) {
              const parent = state.scenario.data.operators[parentOperatorId];

              const getProductOrCategoryOrManufacturer = async (pluralType: string, singularType: string) => {
                const customData = parent.custom;

                if (customData && customData[pluralType] && customData[pluralType].all === 0) {
                  const selection = customData[pluralType][`product_picker_${singularType}`].selection.selected[0];
                  return getDisplayUrl(idShop, singularType, selection.spmId);
                }
                return null;
              };

              // eslint-disable-next-line prefer-destructuring
              defaultDisplayUrl = (parent.custom && parent.custom.urls && Array.isArray(parent.custom.urls.included) && parent.custom.urls.included.length)
                ? parent.custom.urls.included[0]
                : await getProductOrCategoryOrManufacturer('products', 'product')
                  || await getProductOrCategoryOrManufacturer('categories', 'category')
                  || await getProductOrCategoryOrManufacturer('manufacturers', 'manufacturer')
                  || '';
            }
          }
          setDefaultDisplayUrl(defaultDisplayUrl);
          updateOperatorSelectorStep(StepsOperatorSelector.CONFIGURE);
        };

        if (checkIfServiceNeedToBeVerified(meta.Meta.id)) {
          const notConfiguredServices = await getNotConfiguredServices(idShop, getServicesToVerify(meta, state.operators));
          if (notConfiguredServices.length) {
            store.commit('general/setNotConfiguredServices', notConfiguredServices);
            store.commit('general/setIsServicesParametersModalVisible', true);
            store.commit('general/setServiceParameterSuccessAction', serviceParameterSuccessAction);
          } else {
            await serviceParameterSuccessAction();
          }
        } else {
          await serviceParameterSuccessAction();
        }
      } else {
        resetDefaultDisplayUrl();
        add();
      }

      selectLoading.value[item.label] = false;
    };

    const cancel = () => {
      backOperatorSelect(false);
    };

    onBeforeMount(async () => {
      if (state.selectedOperator.currentConfigurationForm !== null && state.leftToolbar.operatorSelectorStep !== StepsOperatorSelector.SELECT) {
        const form = state.selectedOperator.currentConfigurationForm.id;
        const meta = Object.values(Metadata).filter((item) => item?.Meta.id === form);
        if (meta.length) {
          const operatorMeta: OperatorMeta = {
            label: meta[0].Meta.label,
            icon: meta[0].Meta.icon,
            kind: meta[0].Meta.kind,
            component: meta[0].Meta.component,
            disabled: false,
          };
          await select(operatorMeta, state.selectedOperator.currentConfigurationForm);
        }
      } else if (state.scenario.type === TypeCampaignEnum.BULK) {
        if (!state.selectedOperator.possibleOutputs.action.length && !state.selectedOperator.possibleOutputs.filtre.length
          && state.selectedOperator.possibleOutputs.declencheur.length === 1) {
          const meta = Object.values(Metadata).filter((item) => item?.Meta.id === 'boxchoosebulklist');
          if (meta.length) {
            const operatorMeta: OperatorMeta = {
              label: meta[0].Meta.label,
              icon: meta[0].Meta.icon,
              kind: meta[0].Meta.kind,
              component: meta[0].Meta.component,
              disabled: false,
            };
            await select(operatorMeta);
          }
        }
      }
    });

    watch(signalSave, async () => {
      await add();
    });

    watch(() => data.value, () => {
      setCurrentConfigurationForm(data.value);
    }, { deep: true });

    return {
      t,
      state,
      step,
      selected,
      select,
      add,
      cancel,
      StepsOperatorSelector,
      data,
      action,
      idShop,
      configuringSegment,
      formValidation,
      selectLoading,
    };
  },
});
</script>

<style lang="scss" scoped>
.boxes-listing-container {
  > div {
    margin-bottom: 15px;
    h3 {
      font: bold 12px Sans-Serif;
      letter-spacing: 2px;
      text-transform: uppercase !important;
      background: #607D8B82;
      color: #fff;
      padding: 5px 10px;
      margin: 0 0 10px 0;
      line-height: 24px;
    }
  }
}
</style>
