<template>
  <div
    class="preview-container"
    :class="[
      layout === 'list' ? 'flex flex-column md:flex-row preview-container--list' : 'preview-container--grid',
    ]"
  >
    <div
      v-if="!loading && !displaySpinner"
      :class="layout === 'list' ? 'md:w-10rem' : ''"
    >
      <Image
        v-if="file.type === 'image'"
        :src="previewUrl"
        :alt="file.description || 'alt'"
        :image-class="layout === 'list' ? '' : 'gallery-image'"
        @load="handleImageLoaded"
      >
        <!--<template #preview="slotProps">
          <img src="/image.jpg" alt="preview" :style="slotProps.style" @click="slotProps.onClick" />
        </template>-->
      </Image>
      <div
        v-else
        class="flex"
      >
        <i
          class="text-6xl"
          :class="iconsByType[file.type || 'binary']"
        >
        </i>
      </div>
    </div>
    <div
      v-if="loading"
      :class="layout === 'list' ? 'md:w-10rem' : ''"
    >
      <Skeleton
        width="100%"
        height="140px"
      />
    </div>
    <div
      v-if="!loading && displaySpinner"
      :class="layout === 'list' ? 'md:w-10rem' : ''"
    >
      <ProgressSpinner
        class="progress-spinner"
        style="width: 25px; height: 25px; margin: auto"
        stroke-width="8"
        fill="var(--surface-ground)"
        animation-duration="1.0s"
        aria-label="Custom ProgressSpinner"
      />
    </div>
    <div
      v-if="layout === 'list' && !loading && displayChooseFile && !displaySpinner"
      class="flex flex-column md:flex-row justify-content-start md:align-items-start sm:align-items-center flex-1 gap-4"
    >
      <div class="flex flex-column align-items-start gap-2">
        <div
          v-if="displayPreviewOption"
          class="flex w-full flex-row-reverse md:flex-row"
        >
          <Button
            :label="t('fileManager.actions.preview')"
            icon="far fa-eye"
            class="p-button-secondary w-full"
            @click="handlePreviewImage"
          />
        </div>
        <div class="flex w-full flex-row-reverse md:flex-row">
          <Button
            :label="t(chooseImageLabel)"
            icon="far fa-check"
            class="p-button-secondary w-full"
            @click="handleChooseFile"
          />
        </div>
        <div
          v-if="addMoveButton"
          class="flex w-full flex-row-reverse md:flex-row"
        >
          <Button
            :label="t('fileManager.actions.moveToFolder')"
            icon="far fa-file-export"
            class="p-button-secondary w-full"
            @click="handleMoveFile"
          />
        </div>
      </div>
    </div>
    <div
      v-if="layout !== 'list' && !loading && displayChooseFile && !displaySpinner"
      class="principal-action-buttons"
    >
      <Button
        :label="t(chooseImageLabel)"
        icon="far fa-check"
        class="p-button-secondary"
        @click="handleChooseFile"
      />
    </div>
    <div
      v-if="layout !== 'list' && !loading && displayPreviewOption && !displaySpinner"
      class="preview-option"
    >
      <Button
        icon="far fa-eye"
        class="p-button-secondary p-button-icon-only p-button-text text-white"
        @click="handlePreviewImage"
      />
    </div>
    <div
      v-if="file.source"
      class="file-source px-2 py-1"
    >
      <span class="font-semibold text-sm">{{ file.source }}</span>
    </div>
    <div
      v-if="!loading && !displaySpinner"
      class="action-buttons"
    >
      <SpmOverlayPanel
        class-trigger="flex align-items-center justify-content-center action-buttons-trigger"
      >
        <template #trigger>
          <Button
            icon="far fa-ellipsis-v fa-xl"
            class="p-button-secondary"
          />
        </template>
        <SpmPanelMenu
          :items="items"
        />
      </SpmOverlayPanel>
    </div>
  </div>
  <Dialog
    v-model:visible="displayImagePreview"
    :header="t('templates.preview')"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ maxWidth: '50vw', width: '100%' }"
    :content-style="{ textAlign: 'center' }"
    :dismissable-mask="true"
    :modal="true"
  >
    <img
      v-if="selectedImage"
      :src="selectedImage.url"
      :alt="t('templates.preview')"
      :style="{ height: 'auto', width: '100%' }"
    >
    <div
      v-if="file.source"
      class="file-source px-2 py-1"
    >
      <span class="font-semibold text-sm">{{ file.source }}</span>
    </div>
  </Dialog>
  <ChooseDirectoryModal
    v-if="displayChooseDirectoryModal"
    :display-modal="displayChooseDirectoryModal"
    @on-close-dialog="onCloseChooseDirectoryModal"
    @on-choose-folder="onChooseFolder"
  />
  <ConfirmPopup />
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  Ref,
  PropType,
  onMounted,
  computed,
} from 'vue';

import Button from 'primevue/button';
import ConfirmPopup from 'primevue/confirmpopup';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import Skeleton from 'primevue/skeleton';

import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { MenuItem } from 'primevue/menuitem';
import Image from 'primevue/image';

import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import ChooseDirectoryModal from '@/components/file-manager/ChooseDirectoryModal.vue';

import { UserState } from '@/composables/User';
import { deleteMedia, moveFile } from '@/composables/configs/configuration';

import { isImage } from '@/helpers/Files';

import { iconsByType } from '@/configs/file-manager';

import { File } from '@/types';

import { useI18n } from 'vue-i18n';

import { showToastSuccess, showToastError } from '@/helpers';

export default defineComponent({
  name: 'FileItem',

  components: {
    Button,
    ConfirmPopup,
    Dialog,
    ProgressSpinner,
    SpmOverlayPanel,
    SpmPanelMenu,
    Skeleton,
    Image,
    ChooseDirectoryModal,
  },

  props: {
    file: {
      type: Object as PropType<File>,
      required: true,
    },

    layout: {
      type: String,
      required: false,
      default: 'grid',
    },

    addMoveButton: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  emits: ['on-choose-file', 'on-delete-item', 'on-move-item'],

  setup(props, context) {
    const { t } = useI18n();
    const selectedImage: Ref<File | undefined> = ref();
    const idShop: number = UserState.activeShop ? UserState.activeShop.id : 0;
    const confirm = useConfirm();
    const toast = useToast();

    const displayImagePreview = ref(false);
    const displaySpinner = ref(false);
    const displayChooseFile = ref(false);
    const displayPreviewOption = ref(false);
    const loading = ref(false);
    const displayChooseDirectoryModal = ref(false);

    const chooseImageLabel = computed(() => (props.file.source ? 'fileManager.actions.download' : 'fileManager.actions.useImage'));

    const previewUrl = computed(() => props.file.previewUrl || `https://media.shopimind.io/resize/index.php?src=${props.file.url}&h=120&w=150`);

    const handleChooseFile = () => {
      if (props.file.source) {
        displaySpinner.value = true;
      }
      context.emit('on-choose-file', { file: props.file, action: 'download' }, () => {
        displaySpinner.value = false;
      });
    };

    const handlePreviewImage = () => {
      if (displayPreviewOption.value) {
        selectedImage.value = props.file;
        displayImagePreview.value = true;
      }
    };

    const editWithAI = () => {

      context.emit('on-choose-file', { file: props.file, action: 'editWithAi' });
    };

    const handleDelete = async (event: any) => {
      if (!event) return;
      confirm.require({
        target: event.currentTarget,
        message: t('templateBuilder.panels.fileManager.body'),
        icon: 'fa-solid fa-triangle-exclamation',
        accept: async () => {
          displaySpinner.value = true;
          const result = await deleteMedia(idShop, props.file.base_url ?? '');
          if ((Array.isArray(result.data) && result.data.length) || (result.data)) {
            context.emit('on-delete-item', props.file.id);
            displaySpinner.value = false;
          } else {
            toast.add({
              severity: 'error', summary: t('error'), detail: t('templateBuilder.panels.fileManager.error'), life: 3000,
            });
            displaySpinner.value = false;
          }
        },

      });
    };

    const items: Ref<MenuItem[]> = ref([
      {
        label: t('fileManager.actions.delete'),
        icon: 'far fa-trash',
        command: (event) => { handleDelete(event.originalEvent); },
      },
    ]);

    const handleImageLoaded = () => {
      loading.value = false;
    };

    const handleMoveFile = () => {
      displayChooseDirectoryModal.value = true;
    };

    const onCloseChooseDirectoryModal = () => {
      displayChooseDirectoryModal.value = false;
    };


    const onChooseFolder = async (folderPath: string, callback: Function) => {
      try {
        const result = await moveFile(idShop, props.file.base_url ?? '', folderPath);
        context.emit('on-move-item', result.data.sameFile, props.file.id);
        showToastSuccess(t('fileManager.fileMoved'));
        displayChooseDirectoryModal.value = false;
      } catch (error) {
        showToastError(t('errorMessages.GENERIC_ERROR'));
      } finally {
        callback();
      }
    };

    onMounted(async () => {
      if (props.file.source) {
        loading.value = true;
        items.value = [];
        items.value.push(
          {
            label: t(chooseImageLabel.value),
            icon: 'fa-thin fa-file-arrow-down',
            command: handleChooseFile,
          },
        );
        if (props.file.sourceType === 'images') {
          items.value.push({
            label: t('fileManager.actions.editWithAI'),
            icon: 'fa-light fa-microchip-ai',
            command: editWithAI,
          })
        }
        displayPreviewOption.value = true;
      } else {
        loading.value = true;
        const isFileImage = await isImage(props.file.url);
        if (isFileImage) {
          items.value.push(
            {
              label: t('fileManager.actions.editWithAI'),
              icon: 'fa-light fa-microchip-ai',
              command: editWithAI,
            },
          );
          displayChooseFile.value = true;
          displayPreviewOption.value = true;
        }
        loading.value = false;
      }
      if (props.addMoveButton) {
        items.value.push(
          {
            label: t('fileManager.actions.moveToFolder'),
            icon: 'fa-light fa-file-export',
            command: handleMoveFile,
          },
        );
      }
    });

    return {
      t,
      displayImagePreview,
      selectedImage,
      displaySpinner,
      items,
      displayChooseFile,
      loading,
      previewUrl,
      chooseImageLabel,
      iconsByType,
      displayPreviewOption,
      displayChooseDirectoryModal,

      handleImageLoaded,
      handleChooseFile,
      handlePreviewImage,
      handleMoveFile,
      onCloseChooseDirectoryModal,
      onChooseFolder,
    };
  },
});
</script>

<style lang="scss" scoped>
$img-size: 12rem;

.preview-container {
  &--grid {
    position: relative;
    margin: 1rem auto !important;
    min-height: 140px;

    &__hovered {
      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      &::after {
        content: "";
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        min-height: 140px;
        height: 100%;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .principal-action-buttons {
        display: block;
        z-index: 1;
        position: absolute;
        bottom: -40px;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
      }

      .preview-option {
        display: block;
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
      }

      .file-source {
        display: block;
        z-index: 1;
        position: absolute;
        bottom: 3px;
        left: 3px;
        white-space: nowrap;
        background-color: white;
      }

      .action-buttons {
        display: block;
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(0, 0);
        white-space: nowrap;
      }
    }
    .principal-action-buttons {
      display: none;
    }

    .preview-option {
      display: none;
    }

    .file-source {
      display: none;
    }

    .action-buttons {
      display: none;
    }

    .progress-spinner {
      transform: translateY(130%);
    }
  }

  &--list {
    position: relative;
    min-height: 140px;
    .action-buttons {
      display: block;
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(0, 0);
      white-space: nowrap;
    }
  }
}
</style>

<style lang="scss">
.p-dialog-content {
  position: relative;

  .file-source {
    display: block;
    z-index: 1;
    position: absolute;
    bottom: 23px;
    left: 19px;
    white-space: nowrap;
    background-color: white;
  }
}
.gallery-image {
  max-width: 170px;
  max-height: 140px;
}
</style>
